import React, { useEffect, useCallback, useState } from 'react';
import { Redirect, navigate } from '@reach/router';
import styled from 'styled-components';
import moment from 'moment';

import {
  FeaturedSegments,
  ClosedSegments,
  DesktopMenuSelects,
  Step,
  SearchBar,
  //BlogPost,
  Loader,
  NextSegmentsModal,
} from '../components';
import { Gift, ChecklistStepIcon, SearchIcon } from '../components/icons';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
//import useFetch, { method } from '../hooks/useFetch';
import { useStateValue } from '../state';
//import { urls } from '../services/urls';
import { bride, tracking } from '../services/api';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  @media ${medias.laptopMax} {
    flex-direction: column;
    align-items: center;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  @media ${medias.laptopMax} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  height: 120px;
  width: 120px;
`;

/*const TotalSegments = styled.div`
  font-size: ${theme.fontSizeSm + 3}px;
  align-items: center;
  color: ${theme.colors.grey3};
  justify-content: center;
  display: flex;
  text-align: center;
  font-weight: bold;
  margin: 0.35em;
  width: 90%;
`;

const StyledSegmentsSpan = styled.span`
  padding: 0 0.6em;
  justify-content: center;
  display: flex;
  text-align: center;
  font-weight: bold;
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.yellow};
  border: 1px solid ${theme.colors.yellow};
  border-radius: 10%;
`;*/

const Title = styled.h2`
  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.grey3};
  text-align: center;
  margin: 2.5em 0 0.75em 0;
`;

const StyledSearchBar = styled(props => <SearchBar {...props} />)`
  width: 100%;
  padding: 0 6em;
  background-color: ${theme.colors.text_heading};
  @media ${medias.laptopMax} {
    padding: 0;
  }
`;

const RowContainer = styled.div`
  @media ${medias.laptopMin} {
    align-self: center;
    justify-content: space-between;
  }

  @media ${medias.laptopMax} {
    align-items: center;

    & > :not(:first-child) {
      display: none;
    }
  }
  display: flex;
  width: 90%;
  padding: 2em 3em;
`;

const IconContainer = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
`;

const ChecklistIcon = () => (
  <IconContainer>
    <ChecklistStepIcon />
  </IconContainer>
);

export const BrideHomePage = ({ location }) => {
  let firstLogin = null;
  if (location && location.state) {
    firstLogin = location.state.firstLogin;
  }
  const [segments, setSegments] = useState(null);
  const [prioritySegments, setPrioritySegments] = useState(null);
  const [finishedSegments, setFinishedSegments] = useState(null);
  //const [leftSegments, setLeftSegments] = useState(null);
  const [unfinishedSegments, setUnfinishedSegments] = useState(null);
  //const [blogPosts, setBlogPosts] = useState(null)
  const [nextSegmentsModal, setNextSegmentsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    {
      session: { userId, selectedEvent, eventFinished, date },
    },
  ] = useStateValue();

  useEffect(() => {
    if (firstLogin) {
      navigate('/app/noivos/inicio', { state: { firstLogin: false } });
      window.location.reload();
    }
  }, [firstLogin]);

  /*
  const fetchBlogPosts = useCallback(async () => {
    await fetch(
      'https://blog.vestidasdebranco.com.br/wp-json/wp/v2/posts?tags=6'
    ).then(response => response.json().then(data => handleBlogPostsData(data)));
  }, []);

  useEffect(() => {
    fetchBlogPosts();
  }, [fetchBlogPosts]);
  */

  const fetchSegments = useCallback(async () => {
    const fetchMappedSegments = async () => {
      const response = await bride.mappedSegments(selectedEvent);
      if (response && response.ok && response.data) {
        setSegments(response.data);
      }
    };

    const fetchPrioritySegments = async () => {
      const response = await bride.prioritySegments(selectedEvent);

      if (response && response.ok && response.data) {
        setPrioritySegments(response.data);
      }
    };

    const fetchFinishedSegments = async () => {
      const response = await bride.finishedSegments(selectedEvent);

      if (response && response.ok && response.data) {
        setFinishedSegments(response.data);
      }
    };

    /*const fetchLeftSegments = async () => {
      const response = await bride.leftSegments(selectedEvent);

      if (response && response.ok && response.data) {
        setLeftSegments(response.data);
      }
    };*/

    const fetchUnfinishedSegments = async () => {
      const response = await bride.unfinishedSegments(selectedEvent);

      if (response && response.ok && response.data) {
        setUnfinishedSegments(response.data);
      }
    };
    setLoading(true);
    fetchUnfinishedSegments();
    fetchMappedSegments();
    fetchPrioritySegments();
    fetchFinishedSegments();
    //fetchLeftSegments();
    setLoading(false);
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEvent) {
      fetchSegments();
    }
  }, [selectedEvent, fetchSegments]);

  const localFirstLogin = localStorage.getItem('firstLogin');

  if (localFirstLogin === '1') {
    return <Redirect to="/app/noivos" />;
  }

  const updateSegments = async () => {
    toast.info('Atualizando...');
    setLoading(true);
    setTimeout(async () => {
      fetchSegments();
    }, 2400);
  };

  const handleDateActive = () => {
    const now = moment();
    const deadlineDate = now.subtract(30, 'days').format('YYYY-MM-DD');

    return deadlineDate <= date;
  };

  /*
  const handleBlogPostsData = data => {
    const mappedData = data.map(post => ({
      id: post.id,
      title: post.title.rendered,
      description: post.excerpt.rendered,
      link: post.link,
      imageCode: post.featured_media,
    }))

    setBlogPosts(mappedData)
  }
  */

  const handleSearch = async (input, dropdown, isSegment, segmentName) => {
    navigate('/app/noivos/fornecedores', {
      state: { input, dropdown, isSegment, segmentName },
    });

    await tracking.search({
      userId,
      eventId: selectedEvent,
      input: isSegment ? `segmento-${input}` : input,
      module: 'fornecedor',
    });
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <StepsWrapper>
          <DesktopMenuSelects updateSegments={event => updateSegments(event)} />
          <Step
            icon={<ChecklistIcon />}
            title="Passo 1"
            description="Organize os orçamentos do seu evento"
            path="orcamentos"
            active={!eventFinished}
          />
          <Step
            icon={
              <IconContainer>
                <SearchIcon viewBox="-15 -15 50 50" />
              </IconContainer>
            }
            title="Passo 2"
            description="Pesquise fornecedores e solicite orçamento"
            path="fornecedores"
            active={!eventFinished}
          />
          <Step
            icon={
              <ImageContainer>
                <Gift />
              </ImageContainer>
            }
            title="Passo 3"
            description="Troque seus pontos por prêmios"
            path="premios"
            active={handleDateActive()}
          />
        </StepsWrapper>
        <StepsWrapper>
          {prioritySegments && finishedSegments && segments && !loading ? (
            <React.Fragment>
              <FeaturedSegments
                data={prioritySegments}
                onClickEdit={() => setNextSegmentsModal(true)}
                eventDate={date}
                updateData={() => updateSegments(selectedEvent)}
              />
              <ClosedSegments data={segments} segments={finishedSegments} />
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </StepsWrapper>
      </ContentWrapper>

      <Title>Encontrar fornecedores</Title>
      <StyledSearchBar
        placeholder="O que você procura?"
        onClickSearch={handleSearch}
        segmentsDropdown={true}
        noRegion
        home
      />
      <RowContainer>
        {/*blogPosts &&
        blogPosts.map(item => <BlogPost key={item.id} data={item} />)*/}
      </RowContainer>
      {unfinishedSegments ? (
        <NextSegmentsModal
          opened={nextSegmentsModal}
          closeModal={() => setNextSegmentsModal(false)}
          data={unfinishedSegments}
          updateData={() => updateSegments(selectedEvent)}
        />
      ) : null}
    </Wrapper>
  );
};

export default BrideHomePage;
