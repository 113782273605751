import React, { useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import moment from 'moment';

import '../components/Calendar.css';
import {
  SupplierCalendarCard,
  Tabs,
  Loader,
  CalendarReports,
  ConfirmModal,
  /*CheckWeekModal*/
} from '../components';
import SupplierBridesCard from '../components/SupplierBrideCard';
import useFetch, { method } from '../hooks/useFetch';
//import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import { PageWrapper, PageTitle } from '../components/pageComponents';
import { urls } from '../services/urls';
import { supplier, supplierContent } from '../services/api';
import { toast } from 'react-toastify';
import CardContainer from '../components/CardContainer';
import { TwoWayIcon } from '../components/icons/SupplierIcons';

const Wrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const StyledP = styled.p`
  font-weight: 400;
  font-size: ${theme.fontSize}px;
  justify-content: center;
  text-align: center;
  padding-bottom: 0.7em;
  padding-top: ${({ spaceTop }) => (spaceTop ? '0.6em' : '0')};
`;

const StyledSpan = styled.span`
  ${({ btn }) => btn && `cursor: pointer;`}
  text-align: center;
  justify-content: center;
  font-size: ${theme.fontSize + 2}px;
  color: #202327;
  background-color: ${theme.colors.yellow};
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  ${({ isSelected, btn }) =>
    isSelected &&
    btn &&
    `
  padding: 0.6em;
  text-decoration: underline 5px ${theme.colors.yellow};
  font-size: ${theme.fontSizeMed}px;
  font-weight: bold;
  `}
  ${({ isSelected, btn }) =>
    !isSelected &&
    btn &&
    `transition: color 0.2s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    text-decoration underline black;
    padding: 0.4em 0;
    }`}
  margin: 0.3em 1em;
`;

const ConfirmedLine = styled.div`
  height: 3px;
  width: 50%;
  ${({ half }) => !half && 'margin-left: 25%;'}
  background-color: ${({ markedDate }) => (markedDate ? '#7FFF00' : 'red')};
`;

const UnavailableLine = styled.div`
  height: 3px;
  width: 50%;
  ${({ half }) => !half && 'margin-left: 25%;'}
  background-color: black;
`;

const OpportunityLine = styled.div`
  background-color: ${theme.colors.primary};
  height: 3px;
  width: 50%;
  ${({ half }) => !half && 'margin-left: 25%;'}
`;

const LineView = styled.div`
  width: 50%;
  height: 3px;
  display: flex;
  margin-left: 25%;
`;

const UsageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  flex-wrap: ${({ multiline }) => (multiline ? 'nowrap' : 'wrap')};
  width: 100%;
  padding-top: 0;
  margin-top: 0;
`;

const StyledButton = styled.button`
  display: inline-block;
  font-weight: 400;
  font-size: 0.8rem;
`;

const StyledTwoWayIcon = styled(TwoWayIcon)`
  object-fit: contain;
`;

const IconContainer = styled.span`
  max-width: 1em;
`;

const StyledButtonContainer = styled.div`
  cursor: pointer;
  padding-bottom: 0.8em;
  margin-bottom: 0.6em;
  margin-left: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border-radius: 0.25rem;
  transition: color 0.4s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.4s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  text-decoration: none;
  color: #202327;
  background-color: ${theme.colors.yellow};
  :hover {
    background-color: #202327;
  }
  height: 21px;
`;

const BtnSpan = styled.span`
  :hover {
    color: ${theme.colors.white};
  }
`;

const RadioGroup = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const SupplierCalendarPage = () => {
  const currentMonth = Number(moment().format('MM')) - 1; // isso traz o mês passado e nao atual ???

  const [budgetData, setbudgetData] = useState(null);
  const [opportunitiesData, setOpportunitiesData] = useState(null);
  //const [markedData, setMarkedData] = useState(null);
  const [unmarkedData, setUnmarkedData] = useState(null);
  const [agendaMarkMode, setAgendaMarkMode] = useState(false);
  const [dayEvents, setDayEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bridesLoading, setBridesLoading] = useState(false);
  const [markingDates, setMarkingDates] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  /*const [checkWeek, setCheckWeekModal] = useState(false);
  const [markMode, setMarkMode] = useState(false); // Marcando: Diponíveis = true, Indisponíveis = false
  const [clickedDate, setClickedDate] = useState(null);*/

  const tabsContent = [
    {
      id: 0,
      title: 'ORÇAMENTOS',
    },
    {
      id: 1,
      title: 'CONFIRMADOS',
    },
    {
      id: 2,
      title: 'EVENTOS DISPONÍVEIS',
    },
  ];

  useFetch({
    url: urls.supplierContent.getSupplierEvents(currentMonth),
    method: method.GET,
    condition: () => !budgetData,
    callback: data => {
      if (data) {
        setbudgetData(data);
      }
    },
  });

  useFetch({
    url: urls.supplierContent.getOpportunitiesFilteredBrides(currentMonth),
    method: method.GET,
    condition: () => !opportunitiesData,
    callback: data => {
      if (data) {
        setOpportunitiesData(data);
      }
    },
  });

  /*const { doUpdate: updateDates } = useFetch({
    url: urls.supplierContent.getMarkedAgendaDates,
    method: method.GET,
    condition: () => !markedData,
    callback: data => {
      setMarkedData(data);
    },
  });*/

  useFetch({
    url: urls.supplierContent.getUnavailableAgendaDates,
    method: method.GET,
    condition: () => !unmarkedData,
    callback: data => {
      setUnmarkedData(data);
    },
  });

  const handleClickDay = async value => {
    if (isLoading || bridesLoading) {
      return toast.error(
        'Erro. A agenda ainda não havia carregado. Aguarde e tente novamente.'
      );
    }
    if (agendaMarkMode) {
      let date = moment(value).format('YYYY-MM-DD');
      markUnavailableDay(date);
      /*if (markMode) {
        markAvailableDay(date);
      }
      if (!markMode) {*/
      //setClickedDate(date);
      //setCheckWeekModal(true);
      //}
    } else {
      fetchBridesByDay(value);
    }
  };

  const removeAllMarkedDates = async () => {
    setIsLoading(true);
    const response = await supplierContent.unmarkAllDates();
    if (response.ok && response.data) {
      toast.success('As datas marcadas foram removidas com sucesso.');
      //setMarkedData([]);
      setUnmarkedData([]);
    } else {
      toast.error('Erro ao remover as datas inseridas');
    }
    setIsLoading(false);
  };

  const fetchBridesByDay = async (value, pageNumber) => {
    setBridesLoading(true);
    const response = await supplierContent.getBridesByDay(
      moment(value).format('YYYY-MM-DD'),
      pageNumber
    );
    if (response.data && response.ok) {
      setDayEvents(response.data.eligibleEvents);
    }
    const response2 = await supplier.getSupplierEvents(
      null,
      moment(value).format('YYYY-MM-DD')
    );
    if (response2.data && response2.ok) {
      setbudgetData(response2.data);
    }
    const response3 = await supplier.getOpportunitiesFilteredBrides(
      null,
      moment(value).format('YYYY-MM-DD')
    );

    if (response3.data && response3.ok) {
      setOpportunitiesData(response3.data);
    }
    setBridesLoading(false);
  };

  /*const markAvailableDay = async value => {
    setIsLoading(true);
    if (markingDates) {
      const response = await supplierContent.markDate(value);
      if (response.ok && response.data) {
        toast.success('Data marcada como disponível com sucesso.');
        if (markedData) {
          setMarkedData([...markedData, value]);
        } else {
          setMarkedData(value);
        }
        if (response.data.previouslyMarked) {
          let filtered = unmarkedData.filter(v => v !== value);
          setUnmarkedData(filtered);
        }
        setIsLoading(false);
        updateDates();
      }
      if (response.status === 412) {
        toast.error('A data já foi marcada!');
        setIsLoading(false);
      }
    }
    if (!markingDates) {
      const response = await supplierContent.unmarkDate(value);
      if (response.ok && response.data) {
        toast.info('Marcação de data retirada com sucesso.');
        if (markedData) {
          setMarkedData([...markedData.filter(item => item !== value)]);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error('Erro ao desmarcar datas');
      }
    }
  };*/

  const markUnavailableDay = async (value, wholeWeek) => {
    if (markingDates) {
      const response = await supplierContent.markDate(value, true, wholeWeek);
      if (response.ok && response.data) {
        /*if (wholeWeek && response.data.previouslyMarked) {
          let filtered = markedData.filter(v => v !== value);
          setMarkedData(filtered.concat(...response.data.savedDates));
        }
        if (wholeWeek && !response.data.previouslyMarked) {
          if (markedData) {
            setMarkedData(markedData.concat(...response.data.savedDates));
          } else {
            setMarkedData(response.data.savedDates);
          }
          toast.success('Data foi marcada como indisponível.');
        }*/
        if (unmarkedData) {
          setUnmarkedData([...unmarkedData, value]);
        } else {
          setUnmarkedData(value);
        }
        //updateUnavailableDates(); looks like this hook isn't working
      }
      if (response.status === 412) {
        /* if (wholeWeek) {
          if (response.data.savedDates && response.data.savedDates.length > 0) {
            toast.info(
              'A data selecionada já foi marcada. O resto da semana foi marcado como disponível.'
            );
            if (markedData) {
              setMarkedData(markedData.concat(...response.data.savedDates));
            } else {
              setMarkedData(response.data.savedDates);
            }
          } else {
            toast.info(
              'A data selecionada e o resto da semana já foram marcados.'
            );
          }
        } else {*/
        toast.error('A data já foi marcada!');
        //}
        setIsLoading(false);
      }
    }
    if (!markingDates) {
      const response = await supplierContent.unmarkDate(value);
      if (response.ok && response.data) {
        toast.info('Marcação de data retirada com sucesso.');
        if (unmarkedData) {
          setUnmarkedData([...unmarkedData.filter(item => item !== value)]);
        } else {
          setUnmarkedData(null);
        }
      } else {
        toast.error('Erro ao desmarcar datas');
      }
      setIsLoading(false);
      //setClickedDate(null);
    }
  };

  const updateMonthData = async activeStartDate => {
    const activeMonth = Number(moment(activeStartDate).format('MM')) - 1;
    setBridesLoading(true);
    const response = await supplier.getSupplierEvents(activeMonth);
    const response2 = await supplier.getOpportunitiesFilteredBrides(
      activeMonth
    );

    if (
      response &&
      response.ok &&
      response.data &&
      response2 &&
      response2.ok &&
      response2.data
    ) {
      setbudgetData(response.data);
      setOpportunitiesData(response2.data);
    }
    setBridesLoading(false);
  };

  const markAsContacted = async id => {
    if (id !== undefined && id !== null) {
      const response = await supplierContent.markBrideAsContacted(id);
      response.ok
        ? toast.success(`Sucesso ao adicionar à Contatados`)
        : toast.error('Erro ao salvar!');
    }
  };

  const ConfirmedCards = () =>
    budgetData && budgetData.eventsData && budgetData.eventsData.length > 0 ? (
      budgetData.eventsData.map(
        (item, index) =>
          item && <SupplierCalendarCard key={index} data={item} />
      )
    ) : (
      <p style={{ textAlign: 'center' }}>Nenhum evento confirmado.</p>
    );

  const OrçamentoCards = () =>
    opportunitiesData &&
    opportunitiesData.filteredEvents &&
    opportunitiesData.filteredEvents.length > 0 ? (
      opportunitiesData.filteredEvents.map(
        (item, index) =>
          item && <SupplierCalendarCard key={index} data={item} />
      )
    ) : (
      <p style={{ textAlign: 'center' }}>Nenhum orçamento disponivel.</p>
    );

  const RenderAvailable = () =>
    dayEvents && dayEvents.length > 0 ? (
      dayEvents.map(
        event =>
          event && (
            <SupplierBridesCard
              key={event.id}
              cardData={event}
              type={0}
              MarkClick={() => markAsContacted(event.id)}
              contract={false}
            />
          )
      )
    ) : (
      <p style={{ textAlign: 'center' }}>Nenhum evento disponível.</p>
    );

  const components = budgetData &&
    opportunitiesData && [
      <>
        {bridesLoading && <Loader />}
        {!bridesLoading && (
          <CardContainer key={0}>
            <OrçamentoCards />
          </CardContainer>
        )}
      </>,
      <>
        {bridesLoading && <Loader />}
        {!bridesLoading && (
          <CardContainer key={1}>
            <ConfirmedCards />,
          </CardContainer>
        )}
      </>,
      <>
        {bridesLoading && <Loader />}
        {!bridesLoading && (
          <CardContainer key={2}>
            <RenderAvailable />
          </CardContainer>
        )}
      </>,
    ];

  const renderCalendarLines = (date, view) => {
    if (view === 'month') {
      /*const hasMarked =
        markedData &&
        markedData.length > 0 &&
        markedData.some(eventDate => {
          return (
            moment(eventDate).format('YYYY-MM-DD') ==
            moment(date).format('YYYY-MM-DD')
          );
        });*/

      const hasUnavailable =
        unmarkedData &&
        unmarkedData.length > 0 &&
        unmarkedData.some(eventDate => {
          return (
            moment(eventDate).format('YYYY-MM-DD') ==
            moment(date).format('YYYY-MM-DD')
          );
        });

      const hasConfirmed =
        budgetData &&
        budgetData.eventsData &&
        budgetData.eventsData.find(({ date: eventDate }) => {
          return (
            moment(eventDate).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
          );
        });

      const hasOpportunity =
        opportunitiesData &&
        opportunitiesData.eventsData > 0 &&
        opportunitiesData.eventsData.find(({ date: eventDate }) => {
          return (
            moment(eventDate).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
          );
        });

      /*if (hasMarked && !hasOpportunity && !hasConfirmed) {
        return <ConfirmedLine markedDate />;
      }*/

      if (hasUnavailable && !hasOpportunity && !hasConfirmed) {
        return <UnavailableLine />;
      } else if (hasUnavailable && hasOpportunity && hasConfirmed) {
        return (
          <LineView>
            <UnavailableLine half />
            <OpportunityLine half />
            <ConfirmedLine half />
          </LineView>
        );
      } else if (/*hasMarked*/ hasOpportunity && hasConfirmed) {
        return (
          <LineView>
            <ConfirmedLine markedDate half />
            <OpportunityLine half />
            <ConfirmedLine half />
          </LineView>
        );
      } else if (/*hasMarked && */ hasUnavailable && hasConfirmed) {
        return (
          <LineView>
            <UnavailableLine />
            <ConfirmedLine half />
          </LineView>
        );
      } else if (hasUnavailable && hasOpportunity) {
        return (
          <LineView>
            <UnavailableLine half />
            <OpportunityLine half />
          </LineView>
        );
      } else if (hasConfirmed) {
        return (
          <LineView>
            <ConfirmedLine markedDate half />
            <ConfirmedLine />
          </LineView>
        );
      } else if (hasOpportunity) {
        return (
          <LineView>
            <ConfirmedLine markedDate half />
            <OpportunityLine half />
          </LineView>
        );
      }

      if (hasUnavailable) {
        return <UnavailableLine />;
      } else {
        return <ConfirmedLine markedDate />;
      }
    }
  };

  return (
    <PageWrapper>
      <PageTitle>AGENDA</PageTitle>
      <UsageContainer>
        <StyledP>
          <strong>Modo atual: </strong>
          {agendaMarkMode ? (
            <>
              Marcar dias <span style={{ color: 'red' }}>indisponíveis</span>
            </>
          ) : (
            'Visualizando eventos e orçamentos para a data selecionada'
          )}{' '}
        </StyledP>
        <StyledButtonContainer>
          <StyledButton onClick={() => setAgendaMarkMode(!agendaMarkMode)}>
            <BtnSpan>
              {agendaMarkMode
                ? 'Trocar para visualização de eventos'
                : 'Trocar para seleção de datas indisponíveis'}
            </BtnSpan>
          </StyledButton>{' '}
          <IconContainer>
            {' '}
            <StyledTwoWayIcon />
          </IconContainer>
        </StyledButtonContainer>
      </UsageContainer>
      <UsageContainer multiline>
        {agendaMarkMode && (
          <>
            <StyledP spaceTop>
              O que deseja fazer agora?
              <RadioGroup>
                <RadioInput
                  type="radio"
                  onChange={() => setMarkingDates(true)}
                  value="phone"
                  checked={markingDates}
                />
                <RadioLabel>Marcar dias</RadioLabel>
              </RadioGroup>
              <RadioGroup>
                <RadioInput
                  type="radio"
                  onChange={() => setMarkingDates(false)}
                  value="whatsapp"
                  checked={!markingDates}
                />
                <RadioLabel>Desmarcar dias</RadioLabel>
              </RadioGroup>
            </StyledP>
          </>
        )}
      </UsageContainer>
      {budgetData || opportunitiesData || unmarkedData ? (
        <>
          <Wrapper>
            {(!isLoading && agendaMarkMode) || !agendaMarkMode ? (
              <Calendar
                onClickDay={value => handleClickDay(value)}
                onActiveDateChange={({ activeStartDate }) =>
                  updateMonthData(activeStartDate)
                }
                onClickMonth={value => updateMonthData(value)}
                calendarType="ISO 8601"
                className="custom-calendar"
                tileContent={({ date, view }) =>
                  renderCalendarLines(date, view)
                }
              />
            ) : (
              <Loader />
            )}
          </Wrapper>
          {agendaMarkMode && !isLoading && (
            <StyledSpan
              style={{ cursor: 'pointer' }}
              onClick={() => setDeleteModal(true)}
            >
              Remover todas as datas marcadas
            </StyledSpan>
          )}
          {!agendaMarkMode && budgetData && (
            <>
              <StyledTabs
                content={tabsContent}
                components={components}
                lineWidth={'100%'}
              />
              <CalendarReports
                total={budgetData.totalEvents}
                done={budgetData.doneEvents}
                confirmed={budgetData.confirmedEvents}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
      <CardContainer>
        <p>Legenda (dias sublinhados)</p>
        <p>
          <span style={{ color: 'red' }}>Vermelho:</span> Contrato no sistema
        </p>
        <p>
          <span style={{ color: theme.colors.yellow, fontWeight: 550 }}>
            Amarelo:
          </span>{' '}
          Algum orçamento para a data
        </p>
        <p>
          <span style={{ color: 'black' }}>Preto:</span> Data marcada como
          indisponível
        </p>
        <p>
          <span style={{ color: '#7FFF00' }}>Verde:</span> Data disponível
        </p>
      </CardContainer>
      <ConfirmModal
        opened={deleteModal}
        marginTop={'400px'}
        closeModal={() => setDeleteModal(false)}
        onClickConfirm={removeAllMarkedDates}
        text="Você tem certeza que deseja desmarcar todas as data inseridas anteriormente?"
      />
      {/*<CheckWeekModal
        opened={checkWeek}
        marginTop={'400px'}
        closeModal={() => setCheckWeekModal(false)}
        setEntireWeek={() => markUnavailableDay(clickedDate, true)}
        unsetEntireWeek={() => markUnavailableDay(clickedDate, false)}
      />*/}
    </PageWrapper>
  );
};

export default SupplierCalendarPage;
