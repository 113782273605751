import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as moment from 'moment';

import { EventsCard, Tabs, EmptyList } from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { useStateValue } from '../state';
import { urls } from '../services/urls';
import Loader from '../components/Loader';
import { PageTitle, PageWrapper } from '../components/pageComponents';
import theme from '../styles/theme';

const AttentionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.2em;
  text-align: center;
`;

const AttentionTitle = styled.h4`
  color: ${theme.colors.text_heading};
  align-self: flex-start;
`;

const AttentionText = styled.p`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  align-self: flex-start;
  text-align: left;
  margin: 0;
`;

const BrideEventsPage = ({ location: { state } }) => {
  const [, setSelectedTab] = useState(0);
  const [openedCard, setOpenedCard] = useState(null);
  const [
    {
      events: { events },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (state) {
      if (state.opened) {
        setOpenedCard(state.opened);
      }
    }
  }, [state]);

  const { doUpdate } = useFetch({
    url: urls.bridesContent.brideEvents,
    method: method.GET,
    condition: () => !events,
    callback: data =>
      dispatch({
        type: 'setEvents',
        newEvents: data,
      }),
  });

  const updatePage = () => {
    dispatch({
      type: 'setEvents',
      newEvents: null,
    });
    doUpdate();
  };

  const filteredEvents = [];

  const tabsContent = [
    {
      id: 0,
      title: 'ANDAMENTO',
    },
    {
      id: 1,
      title: 'REALIZADOS',
    },
  ];

  const deleteEvent = id => {
    const newData = events.filter(
      ({ evento: { codEvento } }) => codEvento !== id
    );

    dispatch({
      type: 'setEvents',
      newEvents: newData,
    });
  };

  const filterEvents = () => {
    const currentDate = moment().format('YYYY-MM-DD');

    filteredEvents[0] = events.filter(
      ({ evento: { data } }) => data > currentDate
    );
    filteredEvents[1] = events.filter(
      ({ evento: { data } }) => data < currentDate
    );
  };

  const CurrentEvents = () =>
    filteredEvents[0].map(event => {
      const { evento: codEvento } = event;
      return (
        <EventsCard
          key={codEvento}
          data={event}
          onClickDelete={() => deleteEvent(codEvento)}
          updatePage={updatePage}
          renderOpen={openedCard === codEvento.codEvento}
          selectedField={openedCard ? state.field : null}
        />
      );
    });

  const FinishedEvents = () =>
    filteredEvents[1].length > 0 ? (
      filteredEvents[1].map(event => {
        const { evento: codEvento } = event;
        return (
          <EventsCard
            key={codEvento}
            data={event}
            onClickDelete={() => deleteEvent(codEvento)}
            updatePage={updatePage}
            renderOpen={openedCard === codEvento}
            selectedField={openedCard ? state.field : null}
          />
        );
      })
    ) : (
      <EmptyList>Lista vazia</EmptyList>
    );

  const components = [<CurrentEvents key={0} />, <FinishedEvents key={1} />];

  return (
    <PageWrapper>
      {events && filterEvents()}
      <PageTitle>EVENTOS</PageTitle>
      <AttentionContainer>
        <AttentionTitle>ATENÇÃO</AttentionTitle>
        <AttentionText>
          Aqui você gerencia o dados do seu próprio evento e de outro(s) que for
          convidado
        </AttentionText>
      </AttentionContainer>
      {events ? (
        <Tabs
          content={tabsContent}
          components={components}
          onClick={id => setSelectedTab(id)}
        />
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default BrideEventsPage;
