import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { toast } from 'react-toastify';

import ModalContainer, { ModalTitle } from './ModalContainer';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { MaterialInput } from '.';
import {
  MaterialInputLabel,
  MaterialSelect,
  MaterialMenuItem,
  MoneyMask,
  EditBrideModal,
} from '../components';
import { supplier } from '../services/api';
import dateUndefined from '../utils/undefinedDate';
import { UserIcon } from './icons';
import { ListDivisor } from './styled';

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto;
  grid-gap: 1em 0.25em;
  border: 0.2px solid ${theme.colors.text_heading};
  border-radius: 0.2em;
  padding: 0.5rem 0 0;
  margin-bottom: 1.5rem;
  align-items: center;
  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    fill: ${theme.colors.primary};
  }
  .button-page {
    background: ${theme.colors.secondary};
    color: white;
    padding: 0.5em 1em;
    border-radius: 0.1em;
  }
  .button-page:hover {
    background: ${theme.colors.yellow};
    color: ${theme.colors.secondary};
    font-weight: bold;
    text-decoration: underline;
  }
  h2 {
    font-weight: bold;
    font-size: 1em;
    margin: 0;
  }
  .contract-success {
    text-align: center;
    font-weight: bold;
  }
  .contract-form {
    position: relative;
    padding: 0 0 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_exit {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    width: auto;
    color: ${theme.colors.grey3};
    font-size: 0.85em;
  }
  .mark-button {
    display: flex;
    align-items: center;
    text-align: center;
    background: ${theme.colors.text_heading};
    align-content: center;
    color: white;
    padding: 0.5em 0.5em;
    border-radius: 0.25em;
  }
  .mark-button:hover {
    background: ${theme.colors.yellow};
    color: ${theme.colors.secondary};
  }
  .marked-button {
    display: flex;
    align-self: center;
    text-align: center;
    background: ${theme.colors.text};
    align-content: center;
    color: white;
    padding: 0.5em 0.5em;
    border-radius: 0.25em;
  }

  @media ${medias.phoneMin} {
    grid-template-columns: auto auto;
    button,
    .contract-form,
    .contract-success {
      /* Um valor alto para o botão estar sempre ao final */
      grid-area: 4 / 1 / 4 / -1;
    }
  }
  @media ${medias.tabletMin} {
    grid-gap: 1em 0.5em;
    grid-area: 3 / 1 / 3 / -1;
    grid-template-columns: repeat(3, auto);
  }
`;

const StyledSpanFontSm = styled.span`
  font-size: ${theme.fontSizeSm} - 1px;
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const EditSpan = styled.span`
  font-size: ${theme.fontSizeSm} - 1px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledSpan = styled.span`
  padding-left: 0.5rem;
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const StyledH2 = styled.h2`
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 50%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const GenerateContractModal = ({
  onCloseModal,
  opened,
  segmentData,
  regionsData,
  clientEmail,
  updateOportunities,
  brideId,
}) => {
  const [value, setValue] = useState('');
  const [segment, setSegment] = useState('');
  const [region, setRegion] = useState('');
  const [sendActionCount, setSendActionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!region || !segment || !value) {
      toast.error('Preencha todos os campos!');
      return;
    }
    setIsLoading(true);
    if (sendActionCount === 0) {
      const val = value.split('.');
      const verif = await supplier.checkContractValue(
        val[0],
        val[1],
        brideId.toString()
      );
      if (!verif.ok) {
        setIsLoading(false);
        return toast.error('Falha ao verificar contratos.');
      }
      // eslint-disable-next-line no-const-assign
      setSendActionCount(sendActionCount + 1);
      if (verif.ok && verif.data && verif.data.contractAmount > 0) {
        setIsLoading(false);
        return toast.info(
          'Há um contrato registrado com mesmo o mesmo evento. Assegure-se de que deseja continuar.',
          { autoClose: false }
        );
      }
    }
    const payload = {
      value,
      codRegion: region,
      codSegment: segment,
      email: clientEmail,
    };

    toast.info('Aguarde... Seu contrato está sendo gerado.');
    const res = await supplier.inviteBrides(payload);
    if (!res.ok) {
      setIsLoading(false);
      return toast.error('Falha ao gerar contrato.');
    }
    setIsLoading(false);
    setValue('');
    setSegment('');
    setRegion('');
    onCloseModal();
    updateOportunities();
    setIsLoading(false);
    return toast.success('Contrato gerado com sucesso!', { autoClose: 9500 });
  };

  return (
    <ModalContainer opened={opened} closeModal={onCloseModal} hasArabesco>
      <ModalWrapper>
        <ModalTitle>REGISTRAR CONTRATO</ModalTitle>

        <MaterialInput
          style={{ width: '100%' }}
          label="Valor do contrato"
          value={value}
          onChange={event => setValue(event.target.value)}
          InputProps={{
            inputComponent: MoneyMask,
          }}
        />
        <Row>
          <StyledFormControl style={{ width: '45%' }}>
            <MaterialInputLabel htmlFor="region">Região</MaterialInputLabel>
            <MaterialSelect
              onChange={event => setRegion(event.target.value)}
              value={region}
              inputProps={{
                name: 'region',
                id: 'region',
              }}
            >
              {regionsData &&
                regionsData.map(({ region, codRegion }) => (
                  <MaterialMenuItem value={codRegion} key={codRegion}>
                    {region}
                  </MaterialMenuItem>
                ))}
            </MaterialSelect>
          </StyledFormControl>

          <StyledFormControl>
            <MaterialInputLabel htmlFor="segment">Segmento</MaterialInputLabel>
            <MaterialSelect
              onChange={event => setSegment(event.target.value)}
              value={segment}
              inputProps={{
                name: 'segment',
                id: 'segment',
              }}
            >
              {segmentData &&
                segmentData.map(({ segment, codSegment }) => (
                  <MaterialMenuItem value={codSegment} key={codSegment}>
                    {segment}
                  </MaterialMenuItem>
                ))}
            </MaterialSelect>
          </StyledFormControl>
        </Row>
        <button
          onClick={handleSubmit}
          className="button-page"
          style={{ width: '50%', marginTop: '1em' }}
          disabled={isLoading}
        >
          {!isLoading ? 'REGISTRAR' : 'Aguarde...'}
        </button>
      </ModalWrapper>
    </ModalContainer>
  );
};

const SupplierBridesCard = ({
  cardData,
  type,
  segmentData,
  regionsData,
  updateOportunities,
  MarkClick,
  UnmarkClick,
  //contract = true,
}) => {
  const {
    clientLogged,
    eventDate,
    clientPhone,
    clientName,
    clientEmail,
    clientCellphone,
    invitedNumber,
    receptionLocal,
    cerimonyLocal,
    nomeEvento,
    investment,
    indicationType,
    indicationBy,
    id,
    //eventId
  } = cardData;
  const [modalOpened, setModalOpen] = useState(false);
  const [markContacted, setMarkContacted] = useState(false);
  const [unmarked, setUnmarked] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const clientFirstName =
    clientName && clientName.length > 0 ? clientName.split(' ')[0] : '';
  const handleMarkClick = () => {
    if (type === 0) {
      // 0 = Disponíveis, 1 = Convidados
      MarkClick();
      setMarkContacted(true);
      toast.success(`${clientFirstName} lembrado em Contatados`);
    }
  };

  const handleUnmarkClick = () => {
    if (type === 2) {
      // Contatados
      UnmarkClick();
      setUnmarked(true);
      toast.success(`${clientFirstName} desmarcado de Contatados`);
    }
  };
  if (type === 2 && unmarked === true) {
    return <Wrapper></Wrapper>;
  } else {
    return (
      <>
        <Wrapper>
          <StyledH2>
            Evento: {nomeEvento} (#{id})
          </StyledH2>
          <StyledSpan>
            Data:{' '}
            {eventDate === dateUndefined
              ? 'indefinida'
              : moment(eventDate).format('DD/MM/YYYY')}
          </StyledSpan>
          <StyledSpan>
            {' '}
            {invitedNumber
              ? `${invitedNumber} convidados. `
              : 'Sem convidados definidos '}
          </StyledSpan>
          <GenerateContractModal
            onCloseModal={() => setModalOpen(false)}
            opened={modalOpened}
            segmentData={segmentData}
            regionsData={regionsData}
            clientEmail={clientEmail}
            updateOportunities={updateOportunities}
            brideId={cardData.id}
          />
          <StyledSpan>
            <UserIcon style={{ fontWeight: 'bold' }} />
            {clientName} ({clientEmail})
          </StyledSpan>
          <StyledSpanFontSm>
            {clientCellphone}
            {clientPhone ? ` / ${clientPhone}` : ''}
          </StyledSpanFontSm>
          {type === 1 && (
            <StyledSpan>
              Completou cadastro e fez primeiro login?{' '}
              {clientLogged === 0 ? (
                'Sim'
              ) : (
                <>
                  Não{' '}
                  {type === 1 && (
                    <EditSpan onClick={() => setEditModal(true)}>
                      {' '}
                      (editar)
                    </EditSpan>
                  )}
                </>
              )}
            </StyledSpan>
          )}
          <StyledSpan>Investimento estimado: R${investment}</StyledSpan>
          {type === 0 && (
            <StyledSpan>
              {markContacted ? (
                <button className="marked-button">CONTATADO</button>
              ) : (
                <button className="mark-button" onClick={handleMarkClick}>
                  Já contatei
                </button>
              )}
            </StyledSpan>
          )}
          {type === 2 && (
            <StyledSpan>
              <button className="mark-button" onClick={handleUnmarkClick}>
                Desmarcar
              </button>{' '}
            </StyledSpan>
          )}

          <StyledSpan>Recepção: {receptionLocal}</StyledSpan>
          <StyledSpan>Cerimônia: {cerimonyLocal}</StyledSpan>
          {type !== 1 && (
            <>
              <StyledSpan>Indicação: {indicationType}</StyledSpan>
              <StyledSpan>Indicado por: {indicationBy}</StyledSpan>
              <button
                onClick={() => setModalOpen(true)}
                className="button-page"
              >
                CONTRATO FECHADO
              </button>
            </>
          )}
        </Wrapper>
        <ListDivisor />
        {editModal && (
          <EditBrideModal
            onCloseModal={() => setEditModal(false)}
            opened={editModal}
            brideData={cardData}
            segmentData={segmentData}
            regionsData={regionsData}
          />
        )}
      </>
    );
  }
};

export default SupplierBridesCard;
