import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { PortfolioCard } from '../containers';
import { medias } from '../utils/mediaQueries';
import { siteContent, bride, tracking } from '../services/api';
import {
  SupplierBudgetForm,
  SmallAdvantagesCard,
  RequestBudgetForm,
} from '../components';
import { Loader, RestrictAccessModal } from '../components';
import { PageWrapper } from '../components/pageComponents';
import { scrollToRef } from '../hooks';
import { useStateValue } from '../state';
import { isBrowser } from '../services/checkSession';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SupplierInfo = styled.div`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1``;

const Subtitle = styled.span`
  @media ${medias.phoneMax} {
    display: flex;
    flex-direction: column;
  }

  button,
  span {
    margin-right: 10px;
  }
`;

const NavigationBar = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #f1f2f2;
  border-top: 1px solid #f1f2f2;
  margin-top: 10px;

  span {
    @media ${medias.phoneMax} {
      width: 25%;
      font-size: 0.9em;
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 150px;
    padding: 0 10px;
    padding-bottom: 2px;
    margin-right: 10px;

    :hover {
      padding-bottom: 0;
      border-bottom: 2px solid #dbbab1;
    }
  }
`;

const MainContainer = styled.div`
  @media ${medias.phoneMax} {
    flex-direction: column;
    align-items: center;
  }

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const CarouselContainer = styled.div``;
const VideoContainer = styled.iframe`
  justify-content: center;
  @media ${medias.phoneMax} {
    width: 100%;
    height: 338px;
  }

  margin: 0;
  width: 100%;
  align-self: center;
  text-align: center;
  height: 338px;
  object-fit: contain;
`;
const FormContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border: 1px solid #040707;
  width: 25%;
  margin: 1em;
  max-height: ${props => (props.logged ? '380px' : 'auto')};
`;

const CardsContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  display: grid;
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

const InfoContainer = styled.section`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  display: flex;
  flex-direction: column;
  width: 70%;
`;

const InfoButton = styled.button`
  border: 1px solid #f1f2f2;
  border-radius: 8px;
  padding: 10px;
`;

const StyledLink = styled.a`
  color: #000000;
  text-decoration: none;
`;

const AboutSection = styled.section`
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const BenefitsSpan = styled.span`
  text-decoration: ${props =>
    props.closedAllSupplierSegments ? 'line-through' : 'none'};
`;

const SupplierPage = props => {
  const [supplierData, setSupplierData] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [advantagesData, setAdvantagesData] = useState(null);
  const [testimonyData, setTestimonyData] = useState(null);
  const [restrictVisible, setRestrictVisible] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);
  const [phoneClicked, setPhoneClicked] = useState(false);
  const [cellphoneClicked, setCellphoneClicked] = useState(false);
  const [instagramClicked, setInstagramClicked] = useState(false);
  const [addressClicked, setAddressClicked] = useState(false);
  const [closedAllSupplierSegments, setClosedAllSupplierSegments] = useState(
    false
  );

  const [
    {
      session: { selectedEvent, userId, selectedRegion, category },
    },
  ] = useStateValue();

  const supplierId = props.pageContext
    ? props.pageContext.id
    : props.location && props.location.state
    ? props.location.state.id
    : null;

  const portfolioRef = useRef(null);
  const videoRef = useRef(null);
  const aboutRef = useRef(null);
  const advantagesRef = useRef(null);
  const reviewsRef = useRef(null);

  const isLoggedIn = isBrowser() && !!localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      const response = await siteContent.getSupplier(supplierId);

      if (response.ok && response.data) {
        setSupplierData(response.data);
      }
    };

    fetchData();
  }, [supplierId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierPortfolio(supplierId);

      if (response.ok && response.data) {
        let video = [];
        let photos = [];
        response.data.map(item =>
          item &&
          (item.source.includes('vimeo') || item.source.includes('yout'))
            ? video.push(item)
            : photos.push(item)
        );
        if (photos) {
          setPortfolioData(photos);
        }
        if (video) {
          setVideoData(video);
        }
      }
    };

    fetchData();
  }, [supplierId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierAdvantages(
        selectedEvent,
        supplierId
      );

      if (response.ok && response.data) {
        if (response.data.brideClosedAllSupplierSegments) {
          setClosedAllSupplierSegments(true);
        } else {
          setAdvantagesData(response.data.advantages);
        }
      }
    };

    selectedEvent && fetchData();
  }, [supplierId, selectedEvent]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierSegments(supplierId);

      if (response.ok && response.data) {
        setSupplierData({
          ...supplierData,
          segmentos: response.data,
        });
      }
    };

    if (supplierData && !supplierData.segmentos) {
      fetchData();
    }
  }, [supplierId, supplierData]);

  useEffect(() => {
    const fetchTestimony = async () => {
      const res = await siteContent.testimonials();

      if (res.ok && res.data && res.data.length > 0) {
        setTestimonyData(res.data);
      }
    };

    if (supplierId) {
      fetchTestimony();
    }
  }, [supplierId]);

  const removeFavorite = async cod => {
    const response = await bride.removeFavorite(selectedEvent, 2, cod);
    if (!response.ok) {
      toast.error('Erro ao remover favorito');
    }
  };

  const addFavorite = async cod => {
    const response = await bride.addFavorite({
      codEvent: selectedEvent,
      codRegion: selectedRegion,
      codAdvantage: cod,
    });

    if (!response.ok) {
      toast.error('Erro ao favoritar benefício.');
    }
  };

  const handleClickBenefits = () => {
    if (isLoggedIn) {
      return scrollToRef(advantagesRef);
    }

    setRestrictVisible(true);
  };

  const handleEmailClick = () => {
    tracking.supplierClick({
      userId,
      supplierId,
      eventId: selectedEvent,
      type: 'email',
    });
    setEmailClicked(true);
  };

  const handlePhoneClick = () => {
    tracking.supplierClick({
      userId,
      supplierId,
      eventId: selectedEvent,
      type: 'telefone',
    });
    setPhoneClicked(true);
  };

  const handleCellphoneClick = () => {
    tracking.supplierClick({
      userId,
      supplierId,
      eventId: selectedEvent,
      type: 'celular',
    });
    setCellphoneClicked(true);
  };

  const handleWhatsappClick = () => {
    tracking.supplierClick({
      userId,
      supplierId,
      eventId: selectedEvent,
      type: 'whatsapp',
    });
  };

  const handleInstagramClicked = () => {
    tracking.supplierClick({
      userId,
      supplierId,
      eventId: selectedEvent,
      type: 'instagram',
    });
    setInstagramClicked(true);
  };

  const handleAddressClicked = () => {
    tracking.supplierClick({
      userId,
      supplierId,
      eventId: selectedEvent,
      type: 'endereco',
    });
    setAddressClicked(true);
  };

  const formatPhoneNumber = phone =>
    phone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');

  const getWhatsAppAddress = () =>
    `https://api.whatsapp.com/send?phone=55${
      supplierData.wppTelCelularNoivos
        ? formatPhoneNumber(supplierData.telCelularNoivos)
        : formatPhoneNumber(supplierData.telFixoNoivos)
    }&text=Ol%C3%A1%2C%20como%20vai%3F%20Sou%20noivo%20da%20Rede%20Celebrar%20e%20estou%20entrando%20em%20contato%20a%20partir%20do%20sistema%20da%20Rede.%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20as%20condi%C3%A7%C3%B5es%20ofertadas.`;

  const hasWhatsApp =
    supplierData &&
    (supplierData.wppTelCelularNoivos === 1 ||
      supplierData.wppTelFixoNoivos === 1);

  return (
    <PageWrapper>
      {supplierData && supplierData.segmentos && portfolioData ? (
        <Wrapper>
          <SupplierInfo>
            <Title>{supplierData.nomeDivulgacao}</Title>
            {isLoggedIn && (
              <Subtitle>
                {supplierData.emailNoivos && (
                  <>
                    {emailClicked ? (
                      <span>Email: {supplierData.emailNoivos}</span>
                    ) : (
                      <InfoButton onClick={handleEmailClick}>
                        Ver Email
                      </InfoButton>
                    )}
                  </>
                )}
                {supplierData.telFixoNoivos && (
                  <>
                    {phoneClicked ? (
                      <span>Telefone: {supplierData.telFixoNoivos}</span>
                    ) : (
                      <InfoButton onClick={handlePhoneClick}>
                        Ver Telefone
                      </InfoButton>
                    )}
                  </>
                )}
                {supplierData.telCelularNoivos && (
                  <>
                    {cellphoneClicked ? (
                      <span>Celular: {supplierData.telCelularNoivos}</span>
                    ) : (
                      <InfoButton onClick={handleCellphoneClick}>
                        Ver Celular
                      </InfoButton>
                    )}
                  </>
                )}
                {hasWhatsApp && (
                  <InfoButton onClick={handleWhatsappClick}>
                    <StyledLink
                      href={getWhatsAppAddress()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <WhatsAppIcon style={{ color: '#DBBAB1' }} />
                      Chamar no WhatsApp
                    </StyledLink>
                  </InfoButton>
                )}
                {supplierData.instagram && (
                  <>
                    {instagramClicked ? (
                      <span>Instagram: {supplierData.instagram}</span>
                    ) : (
                      <InfoButton onClick={handleInstagramClicked}>
                        Ver Instagram
                      </InfoButton>
                    )}
                  </>
                )}
                {supplierData.endereco && (
                  <>
                    {addressClicked ? (
                      <span>Endereço: {supplierData.endereco}</span>
                    ) : (
                      <InfoButton onClick={handleAddressClicked}>
                        Ver Endereço
                      </InfoButton>
                    )}
                  </>
                )}
              </Subtitle>
            )}
          </SupplierInfo>
          <NavigationBar>
            <span onClick={() => scrollToRef(portfolioRef)}>Fotos</span>
            {videoData && videoData.length ? (
              <span onClick={() => scrollToRef(videoRef)}>Vídeo</span>
            ) : (
              ''
            )}
            <span onClick={() => scrollToRef(aboutRef)}>Sobre</span>
            <BenefitsSpan
              closedAllSupplierSegments={closedAllSupplierSegments}
              onClick={handleClickBenefits}
            >
              Benefícios
            </BenefitsSpan>
            {testimonyData && (
              <span onClick={() => scrollToRef(reviewsRef)}>Depoimentos</span>
            )}
          </NavigationBar>
          <MainContainer>
            <InfoContainer>
              <CarouselContainer ref={portfolioRef}>
                <h3>Fotos</h3>
                <PortfolioCard
                  key={Math.random()}
                  content={portfolioData}
                  noButton
                />
              </CarouselContainer>
              {videoData && videoData.length ? (
                <>
                  <h3 ref={videoRef}>Vídeo</h3>
                  {videoData.map(({ source }) =>
                    source && source.includes('vimeo') ? (
                      <VideoContainer
                        src={`https://player.vimeo.com/video/${source
                          .split('')
                          .splice(18)
                          .join('')}?title=0&portrait=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullscreen
                        key={Math.random()}
                      />
                    ) : (
                      <VideoContainer
                        src={
                          'https://www.youtube.com/embed/' +
                          source
                            .split('')
                            .splice(32)
                            .join('')
                        }
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullscreen
                        key={Math.random()}
                      />
                    )
                  )}
                </>
              ) : (
                ''
              )}
              <AboutSection ref={aboutRef}>
                <h3>Sobre</h3>
                {supplierData.resumo}
              </AboutSection>
            </InfoContainer>
            {supplierData && (
              <FormContainer logged={isLoggedIn}>
                {isLoggedIn ? (
                  <SupplierBudgetForm
                    supplierData={supplierData}
                    selectedEvent={selectedEvent}
                  />
                ) : (
                  <RequestBudgetForm supplierCod={supplierId} />
                )}
              </FormContainer>
            )}
          </MainContainer>
          {isLoggedIn && (
            <>
              <MainContainer>
                <InfoContainer>
                  <h3>Benefícios</h3>
                </InfoContainer>
              </MainContainer>
              <section ref={advantagesRef}>
                {advantagesData && advantagesData.length > 0 ? (
                  <CardsContainer>
                    {advantagesData.map(
                      advantage =>
                        advantage &&
                        advantage.isSegmentOpened && (
                          <SmallAdvantagesCard
                            key={advantage.codAdvantage}
                            advantageData={advantage}
                            category={category}
                            removeFavorite={removeFavorite}
                            addFavorite={addFavorite}
                          />
                        )
                    )}
                  </CardsContainer>
                ) : closedAllSupplierSegments ? (
                  <p>
                    Você já fechou contrato no(s) segmento(s) oferecidos por
                    este fornecedor!
                  </p>
                ) : (
                  ''
                )}
              </section>
            </>
          )}
          {testimonyData && testimonyData.length > 0 && (
            <section ref={reviewsRef}>
              <h3>Depoimentos</h3>
              {testimonyData.map((depo, index) => (
                <p key={index}>{depo}</p>
              ))}
            </section>
          )}
        </Wrapper>
      ) : (
        <Loader />
      )}
      <RestrictAccessModal
        onClickClose={() => setRestrictVisible(false)}
        text="Estes benefícios estão disponíveis apenas para eventos que já fazem parte da Rede Celebrar! Quer saber como fazer parte e ter acesso a condições imperdíveis com nossos fornecedores? Clique no botão abaixo!."
        buttonText="FAÇA PARTE"
        link="/facaparte/noivo"
        opened={restrictVisible}
      />
    </PageWrapper>
  );
};

export default SupplierPage;
