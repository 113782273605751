import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { PageWrapper, PageTitle, Title } from '../components/pageComponents';
import {
  Tabs,
  SupplierAdvantagesCard,
  CreateAdvantageForm,
  Loader,
} from '../components';
import { medias } from '../utils/mediaQueries';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { supplierContent } from '../services/api';
import { toast } from 'react-toastify';

const tabsContent = [
  {
    id: 0,
    title: 'ATIVAS',
  },
  {
    id: 1,
    title: 'EM ANÁLISE',
  },
  {
    id: 2,
    title: 'INATIVAS',
  },
];

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.text_heading};
`;

const AmountText = styled.p`
  margin: 8px;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizeMed}px;
`;

const AmountTotal = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.primary};
`;

const AmountBold = styled.strong`
  color: ${theme.colors.primary};
`;

const Text = styled.p`
  text-align: center;
`;

const HalfOverviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  ${({ border }) =>
    border &&
    `
    border-top: 2px solid ${theme.colors.grey3};
  `}
`;

const SupplierAdvantagesPage = () => {
  const [edit, setEdit] = useState(false);
  const [advantages, setAdvantages] = useState(null);
  const [advantagesAmount, setAdvantagesAmount] = useState(null);
  const [regions, setRegions] = useState(null);
  const [segments, setSegments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState(undefined);
  const [form, setForm] = useState({
    codAdvantage: null,
    name: null,
    codSegment: 0,
    discountValue: null,
    codRegion: 0,
    file: null,
    description: null,
    regulation: null,
  });

  const formRef = useRef(null);

  useFetch({
    url: urls.supplierContent.advantages,
    method: method.GET,
    condition: () => !advantages,
    callback: data => setAdvantages(data),
  });

  useFetch({
    url: urls.supplierContent.supplierSegments,
    method: method.GET,
    condition: () => !segments,
    callback: data => setSegments(data),
  });

  useFetch({
    url: urls.supplierContent.supplierRegions,
    method: method.GET,
    condition: () => !regions,
    callback: data => setRegions(data),
  });

  useFetch({
    url: urls.supplierContent.advantagesConsolidation,
    method: method.GET,
    condition: () => !advantagesAmount,
    callback: data => setAdvantagesAmount(data),
  });

  const changeAdvantage = data => {
    const {
      name,
      codSegment,
      description,
      discountValue,
      codRegion,
      regulation,
      resume,
      codAdvantage,
    } = data;

    setEdit(true);
    setForm({
      ...form,
      codAdvantage,
      name,
      codSegment,
      discountValue,
      codRegion,
      resume,
      description,
      regulation,
    });

    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const cleanForm = message => {
    toast.success(message);

    setForm({
      codAdvantage: null,
      name: '',
      codSegment: null,
      discountValue: '',
      codRegion: null,
      file: undefined,
      description: '',
      regulation: '',
    });

    setImageName(undefined);
  };

  const newAdvantage = (id, form) => {
    //eslint-disable-next-line
    const { file, codRegion, codSegment, ...payload } = form;
    const region = regions.filter(
      ({ codRegion }) => codRegion === codRegion
    )[0];
    const segment = segments.filter(
      ({ codSegment }) => codSegment === codSegment
    )[0];
    const newAdv = {
      codAdvantage: id,
      inAnalysis: 1,
      inactive: 0,
      ...region,
      ...segment,
      ...payload,
    };
    setAdvantages([...advantages, newAdv]);
    setAdvantagesAmount({
      ...advantagesAmount,
      analysisAdvantages:
        advantagesAmount && advantages.analysisAdvantages
          ? advantagesAmount.analysisAdvantages + 1
          : 1,
      totalAdvantages:
        advantagesAmount && advantages.totalAdvantages
          ? advantagesAmount.totalAdvantages + 1
          : 1,
    });
    cleanForm('Benefício adicionado com sucesso!');
  };

  const addAdvantage = async () => {
    setLoading(true);
    const { codAdvantage, ...payload } = form;
    if (
      !form.name ||
      form.codSegment === 0 ||
      !form.discountValue ||
      form.codRegion === 0 ||
      !form.description ||
      !form.regulation
    ) {
      toast.error('TODOS os campos do formulário são obrigatórios', {
        autoClose: 5000,
      });
      setLoading(false);
      return;
    }

    if (codAdvantage) {
      const response = await supplierContent.alterAdvantage({
        codAdvantage,
        ...payload,
      });
      response.ok && response.data
        ? cleanForm('Benefício alterado com sucesso!')
        : toast.error('Erro ao alterar benefício');
    } else {
      const response = await supplierContent.addAdvantage(payload);
      response.ok && response.data
        ? newAdvantage(response.data.id, payload)
        : response.status === 412
        ? toast.error(response.data.message)
        : response.status === 413
        ? toast.error('O tamanho da imagem excede o limite suportado!')
        : toast.error('Não foi possivel adicionar benefício!');
    }
    setLoading(false);
    setEdit(false);
  };

  const deleteAdvantage = async (cod, type) => {
    const newArray = advantages.filter(adv => adv.codAdvantage !== cod);
    const response = await supplierContent.deleteAdvantage(cod);
    if (!response.ok) {
      return toast.info(
        'Não é possível excluir este benefício pois possui interações de eventos com ele. Use a opção de inativar caso deseje desabilitar a visualização por parte dos eventos',
        { autoClose: 7000 }
      );
    }
    if (type === 'active') {
      setAdvantagesAmount({
        ...advantagesAmount,
        activeAdvantages:
          advantagesAmount && advantagesAmount.activeAdvantages
            ? advantagesAmount.activeAdvantages - 1
            : -1,
        totalAdvantages:
          advantagesAmount && advantagesAmount.totalAdvantages
            ? advantagesAmount.totalAdvantages - 1
            : -1,
      });
    } else if (type === 'analysis') {
      setAdvantagesAmount({
        ...advantagesAmount,
        analysisAdvantages:
          advantagesAmount && advantagesAmount.analysisAdvantages
            ? advantagesAmount.analysisAdvantages - 1
            : -1,
        totalAdvantages:
          advantagesAmount && advantagesAmount.totalAdvantages
            ? advantagesAmount.totalAdvantages - 1
            : -1,
      });
    } else {
      setAdvantagesAmount({
        ...advantagesAmount,
        inativeAdvantages:
          advantagesAmount && advantagesAmount.inativeAdvantages
            ? advantagesAmount.inativeAdvantages - 1
            : -1,
        totalAdvantages:
          advantagesAmount && advantagesAmount.totalAdvantages
            ? advantagesAmount.totalAdvantages - 1
            : -1,
      });
    }
    setAdvantages(newArray);
    toast.success('Benefício excluído com sucesso');
  };

  const changeAdvantageInativation = async codAdvantage => {
    //const oldState = advantages;
    //const oldAmount = advantagesAmount;
    let newInactivation;
    const currentAdvantage = advantages.find(
      adv => adv.codAdvantage === codAdvantage
    );
    // ? advantagesAmount.active;
    //setAdvantages(newState);
    const payload = {
      codAdvantage,
    };

    const response = await supplierContent.changeAdvantageInativation(payload);
    if (!response.ok) {
      //setAdvantagesAmount(oldAmount);
      //setAdvantages(oldState);
      toast.error('Erro ao alterar benefício');
    } else {
      currentAdvantage.inactive === 0 && currentAdvantage.inAnalysis === 0
        ? setAdvantagesAmount({
            ...advantagesAmount,
            activeAdvantages:
              advantagesAmount && advantagesAmount.activeAdvantages
                ? advantagesAmount.activeAdvantages - 1
                : -1,
            inativeAdvantages:
              advantagesAmount && advantagesAmount.inativeAdvantages
                ? advantagesAmount.inativeAdvantages + 1
                : 1,
          })
        : currentAdvantage.inactive === 0
        ? setAdvantagesAmount({
            ...advantagesAmount,
            inativeAdvantages:
              advantagesAmount && advantagesAmount.inativeAdvantages
                ? advantagesAmount.inativeAdvantages + 1
                : 1,
            analysisAdvantages:
              advantagesAmount && advantagesAmount.analysisAdvantages
                ? advantagesAmount.analysisAdvantages - 1
                : -1,
          })
        : currentAdvantage.inAnalysis === 1
        ? setAdvantagesAmount({
            ...advantagesAmount,
            analysisAdvantages:
              advantagesAmount && advantagesAmount.analysisAdvantages
                ? advantagesAmount.analysisAdvantages + 1
                : 1,
            inativeAdvantages:
              advantagesAmount && advantagesAmount.inativeAdvantages
                ? advantagesAmount.inativeAdvantages - 1
                : -1,
          })
        : setAdvantagesAmount({
            activeAdvantages:
              advantagesAmount && advantagesAmount.activeAdvantages
                ? advantagesAmount.activeAdvantages + 1
                : 1,
            inativeAdvantages:
              advantagesAmount && advantagesAmount.inativeAdvantages
                ? advantagesAmount.inativeAdvantages - 1
                : -1,
          });
      newInactivation = currentAdvantage.inactive === 0 ? 1 : 0;
      currentAdvantage.inactive = newInactivation;
      toast.success(
        `Benefício ${
          newInactivation === 1 ? 'inativado' : 'ativado'
        } com sucesso`
      );
      if (advantages) {
        setAdvantages([
          ...advantages.filter(adv => adv.codAdvantage !== codAdvantage),
          currentAdvantage,
        ]);
      }
      if (!advantages) {
        setAdvantages([currentAdvantage]);
      }
    }
  };

  const inative =
    advantages && advantages.filter(({ inactive }) => inactive === 1);
  const InativeAdvantages = () =>
    advantages &&
    (inative.length > 0 ? (
      inative.map(adv => (
        <SupplierAdvantagesCard
          type="inative"
          data={adv}
          changeInativation={changeAdvantageInativation}
          changeAdvantage={changeAdvantage}
          deleteAdvantage={deleteAdvantage}
          key={adv.codAdvantage}
        />
      ))
    ) : (
      <Text>Nenhum benefício inativo</Text>
    ));

  const active =
    advantages &&
    advantages.filter(
      ({ inactive, inAnalysis }) => inactive === 0 && inAnalysis === 0
    );
  const ActiveAdvantages = () =>
    advantages &&
    (active.length > 0 ? (
      active.map(adv => (
        <SupplierAdvantagesCard
          type="active"
          data={adv}
          changeInativation={changeAdvantageInativation}
          changeAdvantage={changeAdvantage}
          deleteAdvantage={deleteAdvantage}
          key={adv.codAdvantage}
        />
      ))
    ) : (
      <Text>Nenhum benefício ativo</Text>
    ));

  const analysis =
    advantages &&
    advantages.filter(
      ({ inAnalysis, inactive }) => inAnalysis === 1 && inactive === 0
    );
  const AnalysisAdvantages = () =>
    advantages &&
    (analysis.length > 0 ? (
      analysis.map(adv => (
        <SupplierAdvantagesCard
          type="analysis"
          data={adv}
          deleteAdvantage={deleteAdvantage}
          changeInativation={changeAdvantageInativation}
          key={adv.codAdvantage}
        />
      ))
    ) : (
      <Text>Nenhum benefício em análise</Text>
    ));

  const components = [
    <ActiveAdvantages key={0} />,
    <AnalysisAdvantages key={1} />,
    <InativeAdvantages key={2} />,
  ];

  return (
    <PageWrapper>
      <PageTitle>BENEFÍCIOS</PageTitle>
      {advantages ? (
        <>
          <StyledTabs
            content={tabsContent}
            lineWidth={'100%'}
            onClick={() => {}}
            components={components}
          />
          <AmountContainer>
            <AmountText>
              Benefícios ativos:{' '}
              <AmountBold>
                {advantagesAmount && advantagesAmount.activeAdvantages}
              </AmountBold>
            </AmountText>
            <AmountText>
              Benefícios em análise:{' '}
              <AmountBold>
                {advantagesAmount && advantagesAmount.analysisAdvantages}{' '}
              </AmountBold>
            </AmountText>
            <AmountText>
              Benefícios inativas:{' '}
              <AmountBold>
                {advantagesAmount && advantagesAmount.inativeAdvantages}
              </AmountBold>
            </AmountText>
            <HalfOverviewContainer border>
              <AmountTotal>
                Total de benefícios:{' '}
                <strong>
                  {advantagesAmount && advantagesAmount.totalAdvantages}
                </strong>
              </AmountTotal>
            </HalfOverviewContainer>
          </AmountContainer>
        </>
      ) : (
        <Loader />
      )}
      <Title>{edit ? 'Alterar benefício' : 'Cadastrar benefício'}</Title>
      <CreateAdvantageForm
        addAdvantage={addAdvantage}
        regions={regions}
        segments={segments}
        form={form}
        updateField={() => {}}
        setForm={setForm}
        loading={loading}
        imageName={imageName}
        setImageName={setImageName}
        ref={formRef}
      />
    </PageWrapper>
  );
};

export default SupplierAdvantagesPage;
