import React from 'react';
import styled from 'styled-components';
import { medias } from '../utils/mediaQueries';
import moment from 'moment';
import { useFormState } from 'react-use-form-state';
import { toast } from 'react-toastify';
import theme from '../styles/theme';
import { PageWrapper, PageTitle } from '../components/pageComponents';
import CardContainer from '../components/CardContainer';
import {
  MaterialInput,
  MaterialDatePicker,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialSelect,
  Button,
  Loader,
  Tabs,
  SupplierInvitePersonForm,
  SupplierQRCode,
} from '../components';
import { FormControl } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { supplier } from '../services/api';
import { CheckListIcon } from '../components/icons';
import phoneMask from '../utils/phoneMask';

const tabsContent = [
  {
    id: 0,
    title: 'INSERIR EVENTO',
  },
  {
    id: 1,
    title: 'INSERIR PERFIL (PESSOA)',
  },
  {
    id: 2,
    title: 'GERAR QR CODE',
  },
];

const initialFormState = {
  name: '',
  email: '',
  phone: '',
  city: null,
  uf: null,
  codRegion: 0,
  codSegment: 0,
};

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-top: 1.5em;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 100%;
  }
  .half {
    flex-basis: 45%;
  }
  button[type='submit'] {
    background-color: ${theme.colors.text_heading};
    color: white;
    padding: 0.5em 1em;
    margin-top: 2rem;
  }
`;

const InviteButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const SuccessWrapper = styled(PageWrapper)`
  text-align: center;
  button {
    /* color: ${theme.colors.text_heading} !important; */
    margin: 1rem auto 0;
  }
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
`;

// @TODO: filtrar segmentos de acordo com o que o noivo tem
const SupplierInviteEvent = () => {
  const [formState, { text, email, tel, raw }] = useFormState(
    initialFormState,
    {
      withIds: true,
    }
  );
  const supplierSegments = useStaticQuery(graphql`
    {
      segments: allSegments {
        edges {
          node {
            Segmento
            codSegmento
          }
        }
      }
    }
  `);
  const [data, setData] = React.useState(null);
  const [weddingDate, setDate] = React.useState(null);
  const [dateUndefined, setDateUndefined] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [segments, setSegments] = React.useState(
    supplierSegments.segments.edges
  );

  const {
    name,
    email: _email,
    phone,
    codRegion,
    codSegment,
  } = formState.values;

  const isClickAvaliable =
    name &&
    _email &&
    phone &&
    (weddingDate || dateUndefined) &&
    codRegion !== 0 &&
    codSegment !== 0;

  const getSegments = async () => {
    const s = await supplier.getSegments();
    if (s.ok && s.data.segmentCodes) {
      setSegments(
        segments.filter(({ node }) =>
          s.data.segmentCodes.includes(node.codSegmento)
        )
      );
    }
  };

  React.useEffect(() => {
    getSegments();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const getActiveRegions = async () => {
      const res = await supplier.getActiveRegions();
      if (res.ok && res.data) {
        setData(res.data);
      }
    };
    getActiveRegions();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const { codRegion, codSegment, email, phone, name } = formState.values;
    if (!codRegion || !codSegment || !email || !phone || !name) {
      toast.error('Preencha todos os campos!');
      setLoading(false);
      return;
    }

    const res = await supplier.inviteBrides({
      isIndicating: 1,
      ...formState.values,
      weddingDate: dateUndefined
        ? null
        : moment(weddingDate).format('YYYY-MM-DD'),
    });

    res.ok
      ? setSuccess(true)
      : res.status === 413 || res.status === 409
      ? toast.error('Esse úsuario já existe e possui um evento!')
      : toast.error('Houve um problema com seu envio, tente novamente!');

    setLoading(false);
  };

  const resetForm = () => {
    formState.clear();
    setDate(null);
    setSuccess(false);
  };

  if (success) {
    return (
      <SuccessWrapper>
        <PageTitle>Eventos convidados!</PageTitle>
        <p>
          <CheckListIcon /> {formState.values.name} convidado(a) com sucesso!
        </p>
        <Button click={resetForm} text="Convidar outros" />
      </SuccessWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageTitle>Inserir Eventos</PageTitle>
      <CardContainer>
        <Form onSubmit={handleSubmit}>
          <MaterialInput
            disabled={loading}
            {...text('name')}
            required
            label="Nome"
          />
          <MaterialInput
            disabled={loading}
            {...email('email')}
            required
            label="E-mail"
          />
          <RowContainer>
            <MaterialInput
              {...tel('phone')}
              disabled={loading}
              required
              label="Telefone"
              className="half"
              onChange={e =>
                formState.setField('phone', phoneMask(e.target.value))
              }
            />
            <StyledFields>
              <MaterialDatePicker
                label="Data do evento"
                format="DD/MM/YYYY"
                value={weddingDate}
                onChange={setDate}
                disabled={dateUndefined}
                style={{ width: '100%' }}
              />
              <StyledFieldset>
                <Checkbox
                  type="checkbox"
                  name="hasDate"
                  value="Não definida"
                  onChange={() => setDateUndefined(!dateUndefined)}
                  checked={dateUndefined}
                />
                <StyledSpan>Não definida</StyledSpan>
              </StyledFieldset>
            </StyledFields>
          </RowContainer>
          <FormControl required className="half" disabled={loading}>
            <MaterialInputLabel htmlFor="region" shrink>
              Região
            </MaterialInputLabel>
            <MaterialSelect
              {...raw({
                name: 'codRegion',
              })}
              onChange={e => formState.setField('codRegion', e.target.value)}
              inputProps={{
                name: 'region',
                id: 'region',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {data ? (
                data.map(({ codRegiao, Regiao }) => (
                  <MaterialMenuItem value={codRegiao} key={codRegiao}>
                    {Regiao}
                  </MaterialMenuItem>
                ))
              ) : (
                <MaterialMenuItem key={0}>Aguarde...</MaterialMenuItem>
              )}
            </MaterialSelect>
          </FormControl>
          <FormControl required className="half" disabled={loading}>
            <MaterialInputLabel htmlFor="segment" shrink>
              Segmento
            </MaterialInputLabel>
            <MaterialSelect
              {...raw({
                name: 'codSegment',
              })}
              onChange={e => formState.setField('codSegment', e.target.value)}
              inputProps={{
                name: 'segment',
                id: 'segment',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {segments.map(({ node: { codSegmento, Segmento } }) => (
                <MaterialMenuItem value={codSegmento} key={codSegmento}>
                  {Segmento}
                </MaterialMenuItem>
              ))}
            </MaterialSelect>
          </FormControl>
          {loading ? (
            <Loader />
          ) : (
            <InviteButton
              text="INSERIR"
              click={handleSubmit}
              disabled={!isClickAvaliable}
            />
          )}
        </Form>
      </CardContainer>
    </PageWrapper>
  );
};

const components = [
  <SupplierInviteEvent key={0} />,
  <SupplierInvitePersonForm key={1} />,
  <SupplierQRCode key={2} />,
];

const SupplierInvitePage = () => {
  return (
    <PageWrapper>
      <StyledTabs
        content={tabsContent}
        components={components}
        lineWidth={'100%'}
        onClick={() => {}}
      />
    </PageWrapper>
  );
};

export default SupplierInvitePage;
