import React from 'react';
import styled from 'styled-components';
import { PageWrapper } from '../components/pageComponents';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  size: 1em;

  @media ${medias.phoneMax} {
    width: 90%;
  }
`;

const Privacidade = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <h2>Proteção de Privacidade e Dados Pessoais</h2>
        <p>
          Através de sua Política de Proteção de Dados Pessoais, a Rede
          Qualidade Eventos Ltda. (doravante, <b>REDE CELEBRAR</b>) informa seus
          usuários sobre o tipo de dados coletados, o uso que será feito deles e
          seu direito de acesso, modificação ou eliminação de tais dados.
        </p>
        <p>
          A <b>REDE CELEBRAR</b> declara que está em conformidade com os
          regulamentos atuais sobre a Proteção de Dados Pessoais.
        </p>
        <h3>Responsabilidade pelo Processamento de Dados</h3>
        <p>
          A REDE CELEBRAR informa que é responsável pelo controle dos dados
          fornecendo aos usuários o endereço de e-mail
          contato@redecelebrar.com.br, bem como o endereço postal: Rua Guaicuí,
          Loja 4, Coração de Jesus, Belo Horizonte/MG, CEP 30380-380 para
          qualquer comunicação necessária.
        </p>
        <h3>Dados Pessoais</h3>
        <p>
          Como parte da sua prestação de serviços, a REDE CELEBRAR solicita
          dados pessoais dos seus usuários. A REDE CELEBRAR compromete-se a
          coletar apenas os dados necessários para a prestação de seus serviços
          e garante o uso de todos os meios necessários para proteger esses
          dados. Os dados obrigatórios serão marcados com um asterisco (*) nos
          formulários correspondentes. O restante dos dados não obrigatórios
          solicitados será fornecido pelo usuário sob sua responsabilidade e
          consentimento.
        </p>
        <p>
          Os dados podem ser recolhidos através de formulários de inscrição no
          site da REDE CELEBRAR, telefone, redes sociais, whatsapp/telegram ou
          feiras e/ou eventos organizados pela REDE CELEBRAR. No caso de os
          dados serem coletados por meio de terceiros, o uso apropriado e a
          aplicação correta da Política de Proteção de Dados é de
          responsabilidade do terceiro.
        </p>
        <p>
          A recusa em fornecer algumas informações resultará na impossibilidade
          de usar todos ou parte dos serviços oferecidos pela REDE CELEBRAR.
        </p>
        <p>
          O tipo de dados coletados pode variar de acordo com a categoria do
          usuário:
        </p>
        <h4>Casais e convidados</h4>
        <p>
          Para pessoas físicas, usuários caracterizados como respobsáveis,
          casais ou convidados, os seguintes dados podem ser coletados de acordo
          com os serviços aos quais eles se inscreveram e/ou usaram:
        </p>
        <ul>
          <li>Nome e sobrenome.</li>
          <li>E-mail e telefone.</li>
          <li>Redes sociais de nome de usuário.</li>
        </ul>

        <h4>Profissionais</h4>
        <ul>
          <li>Nome e sobrenome.</li>
          <li>Nome da empresa.</li>
          <li>CNPJ</li>
          <li>E-mail, telefones e endereço.</li>
          <li>Atividades</li>
        </ul>

        <h3>Finalidade do uso de dados</h3>
        <p>
          Os dados pessoais fornecidos pelos usuários serão utilizados em
          relação ao uso dos serviços oferecidos pela REDE CELEBRAR e poderão
          ser utilizados pelos fornecedores que fazem parte da plataforma.
        </p>
        <ul>
          <li>Ajuda na Organização do Evento e Escolha dos Fornecedores</li>
          <li>
            Permitir a busca fácil e rápida de empresas para usuários que estão
            organizando seu evento.
          </li>
          <li>
            Colocar em contato os profissionais do setor com futuras pessoas que
            organizam seu evento através de pedidos de informação por correio
            eletrônico ou por telefone ou por busca ativa do fornecedor via
            nossa base de dados
          </li>
          <li>
            Promover os produtos e serviços de usuários profissionais através da
            publicação de um registro profissional (vitrine). Através deste
            perfil profissional, todas as informações relevantes e pertinentes
            do provedor serão compartilhadas com as futuras pessoas/eventos por
            região e setor de atividade.
          </li>
        </ul>
        <p>
          Durante o relacionamento da REDE CELEBRAR com seus usuários ou até que
          o usuário retire seu consentimento, a REDE CELEBRAR reserva-se o
          direito de enviar comunicações eletrônicas relacionadas aos serviços
          oferecidos pela REDE CELEBRAR ou seus parceiros e comunicações gerais
          relacionadas à organização do evento.
        </p>
        <h3>Cessão e Transferência de Dados para Terceiros</h3>
        <p>
          A REDE CELEBRAR compromete-se a implementar todos os meios necessários
          para proteger os dados de seus usuários e para comunicar essas
          informações somente dentro da estrutura da legislação que rege sua
          atividade ou em um dos casos explicados abaixo.
        </p>
        <p>
          O usuário do tipo responsável pelo evento aceita a transferência de
          seus dados para as empresas que aparecem na REDE CELEBRAR para
          eventuais contatos, via email, telefone ou rede social.
        </p>
        <p>
          A REDE CELEBRAR compromete-se a esconder os dados pessoais do usuário
          que apague seu perfil aos fornecedores que fazem parte da plataforma,
          mas não é responsável se esses dados foram previamente coletados pelo
          provedor e o uso feito desses dados. Neste caso, a responsabilidade
          será do referido fornecedor.
        </p>
        <p>
          Os dados dos usuários profissionais serão comunicados a terceiros
          apenas no contexto da conexão entre futuros fornecedores de serviços e
          parceiros ou de acordo com a legislação que rege a atividade da REDE
          CELEBRAR, conforme mencionado acima.
        </p>
        <h3>Periodo de Conservação</h3>
        <p>
          Os dados coletados serão retidos pela duração do uso dos serviços
          oferecidos pela REDE CELEBRAR ou até que o usuário exerça seu direito
          de cancelamento.
        </p>
        <p>
          Após a exclusão do perfil pelo usuário, A REDE CELEBRAR somente
          manterá os dados necessários como parte de suas obrigações legais e/ou
          contratuais. Quando o período de limitação correspondente tiver
          decorrido, os dados serão total e permanentemente excluídos.
        </p>
        <h3>Direitos do Usuário</h3>
        <p>
          O interessado poderá exercer os direitos de acesso, retificação,
          supressão, limitação do uso, portabilidade dos dados ou oposição ao
          uso.
        </p>
        <p>
          A execução destes direitos pode ser feita diretamente entrando em
          contato com a REDE CELEBRAR através do e-mail
          contato@redecelebrar.com.br ou pelo telefone 31 9 8823-0342.
        </p>
        <p>
          No caso em que o usuário considere que há uma violação de seus
          direitos, poderá enviar uma reclamação diretamente à REDE CELEBRAR
          através das informações de contato fornecidas anteriormente.
        </p>
        <h3>Segurança</h3>
        <p>
          A REDE CELEBRAR informa a seus usuários que todas as medidas
          necessárias foram implementadas, tanto do ponto de vista técnico como
          organizacional, para garantir a segurança de seus dados pessoais e
          evitar a perda, alteração ou processamento não autorizado.
        </p>
        <h3>Dados de Menores de Idade</h3>
        <p>
          A inclusão de dados pessoais de menores estará sob total
          responsabilidade do usuário que alega ter os direitos necessários para
          a divulgação dessas informações e se compromete a não publicar sob
          qualquer pretexto o conteúdo que possa prejudicar um menor.
        </p>
        <h3>Contato</h3>
        <p>
          A REDE CELEBRAR fornece aos seus usuários o e-mail de contato
          contato@redecelebrar.com.br e o telefone +55 31 9 8832-0342.
        </p>
      </Wrapper>
    </PageWrapper>
  );
};

export default Privacidade;
